<template>
  <v-card class="mx-auto my-1" :loading="loading">
    <v-card-actions>
      <v-btn @click="$router.go(-1)" small text>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12"> Farmer Name </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6" md="4">
              <v-text-field
                v-model="selectedFarm.gname"
                label="First name"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="4">
              <v-text-field
                v-model="selectedFarm.surname"
                label="Last name"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"> Farmer Address </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6" md="4">
              <v-text-field
                v-model="selectedFarm.brgy.brgyname"
                label="Barangay"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="4">
              <v-text-field
                v-model="selectedFarm.municipal.munname"
                label="City/ Municipal"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12"> Farmer Contact </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6" md="4">
              <v-text-field
                v-model="selectedFarm.contact1"
                label="Primary Contact"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="4">
              <v-text-field
                v-model="selectedFarm.contact2"
                label="Secondary Contact"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
           <v-row class="my-2">
            <v-col cols="12"> 
                City/ Municipal Agriculture Office
                <div class="text-caption">Contact for further Assistance</div>
            </v-col>
          </v-row>
          <v-row class="my-0">
              <v-col cols="12">
                   <v-text-field
                v-model="municipalContact.adminemail"
                label="Email"
                readonly
              ></v-text-field> 
              </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6" md="4">
              <v-text-field
                v-model="municipalContact.contact1"
                label="Primary Contact"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="4">
              <v-text-field
                v-model="municipalContact.contact2"
                label="Secondary Contact"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Farmer Details",
  data() {
    return {
      selectedFarm: [],
      municipalContact: [],
      loading:false,
    };
  },
  computed: {
    ...mapGetters([
      "crops/getSelectedFarm",
      "farmerfarms/getmunicipalInfo",
    ]),

    ...mapActions([
      "farmerfarms/getMunicipalInfo"
    ])
  },

  mounted() {
    this.loading=true;
    this.getSelectedFarm();
    this.loading=false;
  },
  methods: {
    async getSelectedFarm() {
      this.selectedFarm = await this["crops/getSelectedFarm"];
      await this.$store.dispatch('farmerfarms/getMunicipalInfo',this.selectedFarm.municipal.muncode)
      this.getMunicipalContact()
    },

    async getMunicipalContact() {
      this.municipalContact =await this["farmerfarms/getmunicipalInfo"];
    },
  },
};
</script>
